import React, { useState } from "react"
import Input from '../components/Input'
import { Button, Form, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'
import { navTo } from "../utils/navigation"
import api from '../utils/API'
import Header from '../components/header'
import Spinner from '../components/spinner'
import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const IndexPage = () => {
  const [state, setState] = useState({
    accessKey: '',
    mobileNumber: ''
  })
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false)


  const onChange = ({ target: { name, value } = {} }) => {
    setState(p => ({ ...p, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      setIsLoading(true)
      api.login(state.accessKey, state.mobileNumber)
        .then((res) => {
          setIsLoading(false)
          navTo.verify()
        })
        .catch((err, payload) => {
          setIsLoading(false)
          Object.keys(err).map((x) => console.error("error loggin in", x, err[x]))
          if (err.response && err.response.status && err.response.status === 429) {
            setError('Too many requests, please try again later.')
          } else {
            console.error("error loggin in", err)
            setError("Invalid access key and mobile number combination.")
          }

        })
    }
  }

  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        An access key was assigned to you when you gave consent for us to collect and use your data from other banks. You can find your access key in the email sent to you at the time you provided consent. Use the access key in combination with your mobile number to view and manage authorised consents.
      </Tooltip>
    );
  }

  const OtpButton = styled(Button)`
    background: #373737;
    color: #FFFFFF;
    opacity: 1;
    font-family: 'Arial';
    &:hover {  
      opacity: 0.5;
      background: #373737;
      color: #FFFFFF;
    }
  `
  return (
    <Layout>
      <Spinner isLoading={isLoading} />
      <div className="container py-4">
        <Card>
          <Card.Body>
            <Card.Title style={{ color: '#373737 ' }}>Enter these details to receive your one-time password access code to your mobile phone.</Card.Title>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label" style={{ color: '#373737' }}>Access Key
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}><span className="px-1"><FontAwesomeIcon icon={faInfoCircle} style={{ color: '#373737' }} /></span></OverlayTrigger>
                </label>
                <div className="col-sm-10">
                  <Input
                    name='accessKey'
                    onChange={onChange}
                    validateErrorMessage='Access Key cannot be empty'
                    required />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-2 col-form-label" style={{ color: '#373737' }}>Mobile Number</label>
                <div className="col-sm-10">
                  <Input
                    name='mobileNumber'
                    onChange={onChange}
                    className="form-control"
                    validateErrorMessage='Must be a valid Australian mobile number (starts with +61 or 04, no spaces).'
                    pattern="^((0\d{9})|\+61\d{9})$"
                    required />
                </div>
              </div>
              {
                error &&
                <div className="alert alert-danger py-2" >{error}</div>
              }
              <OtpButton variant="dark" className="btn-block" type="submit">Send my one-time password</OtpButton>
            </Form>
          </Card.Body>
        </Card>
      </div>

    </Layout>
  )

}





export default IndexPage
